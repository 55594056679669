<template>
  <!-- BEGIN: Modal Content -->
  <div id="file-upload-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- BEGIN: Modal Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base flex items-center gap-2">
            <app-i18n code="common.importFiles"></app-i18n>
          </h2>
        </div>
        <!-- END: Modal Header -->
        <!-- BEGIN: Modal Body -->
        <div class="modal-body grid grid-cols-12 gap-4 gap-y-8">
          <div class="col-span-12">
            <Dropzone
              :key="key"
              ref-key="dropzoneRef"
              id="dropzoneRef"
              :options="{
                url: 'https://httpbin.org/post',
                // autoProcessQueue: false,
                paramName: 'file',
                thumbnailWidth: 150,
                maxFilesize: 2,
                addRemoveLinks: true,
                acceptedFiles: acceptedFiles,
                maxFiles: 1,
                headers: { 'My-Awesome-Header': 'header value' }
              }"
              class="dropzone"
            >
              <div class="text-lg font-medium">
                {{ i18n('settings.shippingTab.modal.fileUploaderHint') }}
              </div>
            </Dropzone>
          </div>
        </div>
        <!-- END: Modal Body -->
        <!-- BEGIN: Modal Footer -->
        <div class="modal-footer flex justify-center items-center">
          <AppButton
            type="button"
            class="btn bg-theme-31 text-white cursor-pointer"
            :class="!saveLoading ? 'w-24' : ''"
            :disabled="saveLoading || uploadLoading"
            :loading="saveLoading"
            @click="doSubmit"
          >
            <strong>{{ i18n('common.upload') }}</strong>
            <template v-slot:loading>
              <app-i18n code="common.loading"></app-i18n>
              <LoadingIcon
                icon="three-dots"
                color="#FFFFFF"
                style="margin: 0 4px"
              />
            </template>
          </AppButton>
          <button
            type="button"
            data-dismiss="modal"
            class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
            :disabled="saveLoading || uploadLoading"
            @click="doReset"
          >
            <app-i18n code="common.cancel"></app-i18n>
          </button>
        </div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<script>
import { ref, onMounted, provide } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Dropzone from 'dropzone'
import Message from '@/shared/message/toastify'
import { FileUploader } from '@/shared/uploader/file-uploader'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    user: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const hideModal = () => {
      cash('#file-upload-modal').modal('hide')
    }
    const acceptedFiles =
      '.xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    let uploadLoading = ref(false)
    const dropzoneRef = ref()
    const filesToSave = ref([])
    const uploadedFile = ref(null)

    provide('bind[dropzoneRef]', (el) => {
      dropzoneRef.value = el
    })
    const clearDropzone = () => {
      Dropzone.forElement('#dropzoneRef').removeAllFiles(true)
      while (filesToSave.value.length > 0) {
        filesToSave.value.pop()
      }
    }
    const getDropZoneInstance = () => {
      return dropzoneRef.value
    }
    const setupDropZone = () => {
      const elDropzoneRef = getDropZoneInstance()
      elDropzoneRef.dropzone.on('addedfile', async (file) => {
        if (acceptedFiles.includes(file.type)) {
          const path = 'users/file'
          const { publicUrl } = await FileUploader.upload(path, file)
          uploadedFile.value = publicUrl
        }
      })

      elDropzoneRef.dropzone.on('removedfile', (file) => {
        filesToSave.value.pop()
      })

      elDropzoneRef.dropzone.on('error', () => {
        console.log('error')
        uploadedFile.value = null
      })

      elDropzoneRef.dropzone.on('uploadprogress', () => {
        uploadLoading = true
      })
    }
    onMounted(() => {
      setupDropZone()
    })
    return {
      setupDropZone,
      uploadLoading,
      filesToSave,
      getDropZoneInstance,
      clearDropzone,
      hideModal,
      uploadedFile,
      acceptedFiles
    }
  },
  data() {
    return {
      key: true,
      errorMessage: 'Please Add file to upload'
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      saveLoading: 'user/form/uploadLoading'
    })
  },
  methods: {
    ...mapActions({
      doUploadFile: 'user/form/doUploadFile'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (!this.uploadedFile) {
        return false
      }
      return true
    },
    doReset() {
      this.clearDropzone()
      this.$emit('close')
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      await this.doUploadFile(this.uploadedFile)
      this.key = !this.key
      this.doReset()
      this.hideModal()
    }
  }
}
</script>

<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: white !important;
    opacity: 1;
    /* Firefox */
  }
}
</style>
